import { graphql, Link } from "gatsby"
import React from "react"
import moment from "moment";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import SEO from '../components/seo'
import Layout from '../components/Layout'
const baseuri = "https://dmf5.xyz"
const baseuridemo = "http://admin.hovinx.com"

class BlogIndex extends React.Component {

  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const siteDescription = data.site.siteMetadata.description
    const domainName = data.site.siteMetadata.domainName
    const posts = data.privateGraphql.news
    const { currentPage, numPages } = this.props.pageContext
    let curr = 1;
    if (currentPage === undefined) { curr = 1 }
    else { curr = currentPage }
    const isFirst = curr === 1
    const isLast = curr === numPages
    const prevPage = curr - 1 === 1 ? '/' : (curr - 1).toString()
    const nextPage = (curr + 1).toString()
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={`${siteTitle} ${curr === 1 ? '' : ` - Trang ${curr}`}`}
          description={`${siteDescription} ${curr === 1 ? '' : ` - Trang ${curr}`}`}
          canonical={`https://${domainName}`}
        />
        <div className="row">
          {posts.map(node => {
            const title = node.title || node.slugtitle
            return (
              <div className="col-md-4">
                <article class="archive-post mts-post" key={node.slugtitle}>
                  <Link to={node.slugtitle} title={title} class="post-image post-image-full">
                    <div class="featured-thumbnail">
                      <LazyLoadImage
                        className="wp-post-image"
                        alt={title}
                        title={title}
                        src={baseuri + node.image}
                        effect="blur"
                      />
                    </div>
                  </Link>
                  <div class="post-content">
                    <div class="post-meta">
                      <span class="theauthor">
                          <Link to={`/authors/${node.profileSlugName}`}>
                            <LazyLoadImage
                            className="author-avatar"
                            title={node.profileName}
                            src={baseuri + node.profileImage}
                            effect="blur"
                          />
                          {node.profileName}
                          </Link>
                        </span>
                      <span class="thecategory">{node.categoryName !== null ? <Link to={`/${node.slugCategoryName}`}>{node.categoryName}</Link> : "Tin Tức Bóng Đá"}</span>
                      
                    </div>
                    <header>
                      <h2 class="post-title">
                        <Link to={node.slugtitle} title={title}>{title}</Link></h2>
                    </header>
                    <div class="post-excerpt">
                      <p className="tb-post-card__subhead" dangerouslySetInnerHTML={{ __html: node.short }} />
                      <Link to={node.slugtitle} title={title} class="read-more-link">Chi tiết</Link>
                    </div>
                  </div>
                </article>
              </div>
            )
          })}
        </div>
        <div className="row pagination">
          <ul className="col d-flex justify-content-center">
            {!isFirst && (
              <li className="tb-pagination__link">
                <Link to={curr <= 2 ? `/` : `/page/${prevPage}`} rel="prev">
                  ←
                </Link>
              </li>
            )}
            {Array.from({ length: numPages }, (_, i) => {
              if (i < curr + 3 && i > curr - 3) {
                return (
                  <li
                    key={`pagination-number${i + 1}`}
                    className="tb-pagination__link"
                  >
                    <Link
                      to={`/${i === 0 ? '' : 'page/' + (i + 1)}`}
                      className={ i + 1 === curr ? "current":"page-s"}
                    >
                      {i + 1}
                    </Link>
                  </li>
                )
              }
            })}
            {!isLast && (
              <li className="tb-pagination__link">
                <Link to={`/page/${nextPage}`} rel="next">
                  →
                </Link>
              </li>
            )}
          </ul>
        </div>
      </Layout>
    )
  }
}
export default BlogIndex
export const query = graphql`
query ($limit: Int = 21, $skip: Int = 0, $siteId: Int = 1054) {
  site {
    siteMetadata {
      title
      domainName
      description
    }
  }
  privateGraphql {
    news(
      limit: $limit 
      skip: $skip,
      siteId: $siteId
      ) {
        id
        categoryName
        image
        profileName
        profileSlugName
        publishDate
        short
        slugCategoryName
        tags
        slugtags
        slugtitle
        title
        profileImage
    }
  }
}
`
